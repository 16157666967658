import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {
  LogoutRounded as LogoutRoundedIcon,
  PersonRounded as PersonRoundedIcon
} from '@mui/icons-material'
import { ListItemIcon, ListItemText } from '@mui/material'
import AuthContext from '../../util/auth/context'

export default function Navbar() {
  const { logOut } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = ({ currentTarget }) => { setAnchorEl(currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  return (
    <AppBar position='sticky' sx={{ top: 0 }} color='warning'>
      <Toolbar variant='dense'>
        {/* // TEMP */}
        {/* <IconButton
          size='large'
          edge='start'
          color='inherit'
          aria-label='menu'
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton> */}
        <Typography variant='h6' component='div' sx={{ flexGrow: 1 }} textAlign='left'>
          Home
        </Typography>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* // TEMP */}
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem> */}
            <MenuItem onClick={() => { logOut() }}>
              <ListItemIcon>
                <LogoutRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Sign Out</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}