import { useRef } from 'react'
import usePrevious from './usePrevious'

const DEFAULT_VALUE = Symbol()

const useUpdatedValue = function (data, callback) {
  const valueRef = useRef(DEFAULT_VALUE)
  if (usePrevious(data)[1]) { valueRef.current = callback(data) }
  return valueRef.current
}

export default useUpdatedValue