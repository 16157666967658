import { Box, Button, Chip, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { map, uniq } from 'lodash'
import AutocompleteWrapper from '../../form-input/AutocompleteWrapper'
import { Add as AddIcon, Remove as RemoveIcon, Save as SaveIcon } from '@mui/icons-material'
import { getUniqueID } from '../../../util/common'
import useUpdatedValue from '../../../util/hooks/useUpdatedValue'

const StyledDiv = styled('div')({
  textAlign: 'right'
})

const TickboxItemContainer = styled('div')({
  position: 'relative'
})

const getYearMonthObj = (data) => {
  const obj = {}
  data?.forEach((item) => {
    const splitItem = item.split(' ')
    const month = splitItem[0], year = splitItem[1]
    if (!(year in obj)) { obj[year] = [] }

    obj[year].push(month)
  })

  for (let key in obj) {
    obj[key] = uniq(obj[key])
  }

  return obj
}

const processValue = (value) => {
  const yearMonthObj = getYearMonthObj(value)
  const res = {}
  for (let year in yearMonthObj) {
    res[year] = { year, months: yearMonthObj[year] }
  }
  return res
}

const getNewValueItem = () => ({ [getUniqueID()]: null })

export default function ColumnDateValueInput({ data, value: rootValue, update, sx }) {
  const { yearMap, yearOptions } = useUpdatedValue([data], () => {
    const yearMap = getYearMonthObj(data)
    const yearOptions = map(yearMap, (_, label) => ({ label, value: label }))

    return { yearMap, yearOptions }
  })

  const [value, setValue] = useState(() => (getNewValueItem()))

  const updateValue = (id, key, item) => {
    const newItem = { ...(value[id] || {}) }
    if (key === 'year') {
      Object.assign(newItem, item ? {
        year: item.value,
        monthOptions: item.monthOptions,
        months: []
      } : {
        year: null, monthOptions: null, months: []
      })
    } else {
      Object.assign(newItem, {
        months: item || [],
      })
    }

    setValue({
      ...value,
      [id]: newItem
    })
  }

  const onRemove = (id) => {
    const newValue = { ...value }
    delete newValue[id]
    setValue(newValue)
  }

  const onAdd = () => {
    setValue({
      ...value,
      ...getNewValueItem()
    })
  }

  const onApply = () => {
    const res = []

    map(value, (item) => {
      if (!item) { return }
      let { year, months } = item
      if (!year) { return }
      months = months?.length ? months : yearMap[year]
      res.push(
        ...(months.map((month) => (month + ' ' + year)))
      )
    })

    update(res)
  }

  useEffect(() => {
    const newValue = processValue(rootValue)
    Object.assign(newValue, getNewValueItem())
    setValue(newValue)
  }, [rootValue])

  return (
    <Box textAlign='center'>
      {(() => {
        let index = -1

        return map(value, (item, id) => {
          index++
          item = item || {}
          const { year, months } = item
          return (
            <TickboxItemContainer key={id}>
              <AutocompleteWrapper
                options={yearOptions}
                label='Year'
                sx={sx}
                value={year || null}
                onChange={(_, newValue) => {
                  updateValue(id, 'year', newValue)
                }}
                renderOption={(props, { label }) => {
                  return <li {...props}>{label}</li>
                }}
              />

              {item.year &&
                <AutocompleteWrapper
                  options={yearMap[year]}
                  label='Month'
                  sx={sx}
                  value={months || []}
                  onChange={(_, newValue) => {
                    updateValue(id, 'month', newValue)
                  }}
                  renderOption={(props, label) => {
                    return <li {...props}>{label}</li>
                  }}
                  multiple
                />
              }

              <Button
                size='small'
                variant='outlined'
                color='error'
                sx={{ mb: 2 }}
                startIcon={<RemoveIcon />}
                onClick={() => { onRemove(id) }}>
                Remove
              </Button>

              <Chip
                label={index + 1}
                color='primary'
                size='small'
                sx={{ position: 'absolute', left: '5px', top: '-16px' }}
              />
            </TickboxItemContainer>
          )
        })
      })()}

      <StyledDiv sx={{ pb: 2, px: 2 }}>
        <Button
          size='small'
          variant='outlined'
          color='success'
          sx={{ mr: 2 }}
          startIcon={<AddIcon />}
          onClick={onAdd}>
          Add
        </Button>
        <Button
          size='small'
          variant='outlined'
          color='info'
          startIcon={<SaveIcon />}
          onClick={onApply}>
          Apply
        </Button>
      </StyledDiv>
    </Box>
  )

}