import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { CircularProgress } from '@mui/material'
import useSubmit from '../../util/hooks/useSubmit'
import { auth } from '../../util/auth/firebase'
import { toast } from 'react-toastify'
import { Link as RRLink } from 'react-router-dom'
import LoginProviders from '../login-providers/Providers'

export default function BasicSignIn() {
  const [isSubmitting, handleSubmit] = useSubmit(async (resolve, e) => {
    const data = new FormData(e.currentTarget)
    const email = data.get('email')
    const password = data.get('password')

    await signInWithEmailAndPassword(auth, email, password)
      .catch(({ message }) => {
        toast.error(message)
      })

    resolve()
  })

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          pt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            size='small'
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            size='small'
          />
          {/* // TODO */}
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            Sign In
            {isSubmitting && <CircularProgress
              size={24}
              sx={{ position: 'absolute' }}
            />}
          </Button>
          <Grid container columnSpacing={3}>
            <Grid item>
              <Link component={RRLink} to='#'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              Don't have an account?{' '}
              <Link component={RRLink} to='/sign-up'>
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
        <LoginProviders />
      </Box>
    </Container>
  )
}