import { node } from 'prop-types'
import React, { useEffect, useState } from 'react'
import AuthContext from './context.js'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from './firebase.js'
import { toast } from 'react-toastify'

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()

  // TODO
  // Connect with firebase methods
  // const updateUser = useCallback((user) => {
  // }, [])

  const logOut = () => {
    signOut(auth)
      .catch(({ message }) => {
        toast.error(message)
      })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  if (user === undefined) { return null }

  return (
    <AuthContext.Provider
      value={{
        user, logOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: node.isRequired
}

export default AuthProvider
