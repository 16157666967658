import React, { useRef } from 'react'
import GraphContext from './context'
import GraphSection from './GraphSection'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { DEFAULT_ERROR_MESSAGE } from '../../util/constants'
import { useEffect } from 'react'
import { groupBy, map } from 'lodash'
import useUpdatedValue from '../../util/hooks/useUpdatedValue'
import { getObj } from '../../util/common'

// TODO
// Take it to `util` folder
const processGraphResponse = (data) => {
  const processGraph = ({ title, columns, data }) => {
    // TODO
    // Take the date-time values
    // - If possible, insert into each of the data row
    // - Take the highest and lowest, so that user can pick a range to filter

    // TEMP
    // data = data.slice(0, 12)
    // TEMP
    // data = data.filter(({ collectionStart, area }) => {
    //   const time = new Date(collectionStart).getTime()
    //   return !area.startsWith('Rest') &&

    //     // TEMP
    //     // true
    //     (
    //       false
    //       // Before Apr 2003
    //       || time < 1049133600000
    //       // Between Feb 2009 & Apr 2010
    //       // || (time > 1233424800000 && time < 1270058400000)
    //       // Between Feb 2015 & Apr 2016
    //       // || (time > 1422727200000 && time < 1459447200000)
    //       // Between Feb 2022 & Apr 2023
    //       || (time > 1643652000000 && time < 1680285600000)
    //     )
    // })

    return {
      title,
      columns,
      data
    }
  }

  if (Array.isArray(data)) {
    return data.map(processGraph)
  } else {
    return [processGraph(data)]
  }
}

const DEFAULT_GRAPH_FILE_URL = process.env.NODE_ENV === 'production' ?
  'https://firebasestorage.googleapis.com/v0/b/ausdata-test.appspot.com/o/public%2Fgraphs.json?alt=media' :
  'graphs.json'

export default function GraphProvider() {
  const [graphs, setGraphs] = useState(null)
  const [activeGraph, setActiveGraph] = useState(null)

  const [primaryXAxis, setPrimaryXAxis] = useState()
  const [secondaryXAxis, setSecondaryXAxis] = useState()
  const [yAxis, setYAxis] = useState()

  const [selectedValues, setSelectedValues] = useState(() => ({}))
  const selectedValuesRef = useRef(selectedValues)

  const updateSelectedValue = (key, value) => {
    const newValue = selectedValuesRef.current = {
      ...selectedValuesRef.current,
      [key]: value
    }

    setSelectedValues(newValue)
  }

  const data = useUpdatedValue([
    activeGraph, primaryXAxis, secondaryXAxis, yAxis,
    selectedValues
  ], () => {
    if (!activeGraph) { return activeGraph }
    if (!primaryXAxis || !yAxis) { return false }

    const primaryXAxisKey = primaryXAxis.value
    const secondaryXAxisKey = secondaryXAxis?.value
    const yAxisKey = yAxis.value

    const primaryXAxisValues = selectedValues.primaryXAxis
    const secondaryXAxisValues = selectedValues.secondaryXAxis
    // TODO
    // Refactor the following graph data processing into a function
    let graphData = activeGraph.data
    if (primaryXAxisValues) {
      let valueObj = getObj(primaryXAxisValues)
      graphData = graphData.filter((item) => {
        return item[primaryXAxisKey] in valueObj
      })
    }

    if (secondaryXAxisKey && secondaryXAxisValues) {
      let valueObj = getObj(secondaryXAxisValues)
      graphData = graphData.filter((item) => {
        return item[secondaryXAxisKey] in valueObj
      })
    }

    // Grouping by primary key first
    let dataset = groupBy(graphData, (item) => {
      const value = item[primaryXAxisKey]
      return value
    })
    const xAxis = [{
      label: activeGraph.title,
      scaleType: 'band',
      data: Object.keys(dataset)
    }]
    const series = []

    if (secondaryXAxisKey) {
      const secondaryValueObj = {}

      dataset = map(dataset, (items) => (groupBy(items, secondaryXAxisKey)))
      dataset.forEach((obj) => {
        for (let label in obj) { secondaryValueObj[label] = { id: label, label, data: [] } }
      })

      dataset.forEach((obj) => {
        for (let label in secondaryValueObj) {
          secondaryValueObj[label].data.push(
            (obj[label] || []).reduce((prev, cur) => (prev + cur[yAxisKey]), 0)
          )
        }
      })

      series.push(...Object.values(secondaryValueObj))
    } else {
      series.push({
        data: map(dataset, (items) => (
          items.reduce((prev, cur) => (prev + cur[yAxisKey]), 0)
        ))
      })
    }

    return { xAxis, series }
  })

  const { xAxisOptions, yAxisOptions } = useUpdatedValue([activeGraph], () => {
    const columns = activeGraph?.columns || []
    return {
      xAxisOptions: columns.filter(({ type }) => (type !== 'number'))
        .map(({ key: value, name: label }) => ({ value, label })),
      yAxisOptions: columns.filter(({ type }) => (type === 'number'))
        .map(({ key: value, name: label }) => ({ value, label }))
    }
  })

  useEffect(() => {
    if (xAxisOptions?.length && yAxisOptions?.length) {
      xAxisOptions[0] && setPrimaryXAxis(xAxisOptions[0])
      xAxisOptions[1] && setSecondaryXAxis(xAxisOptions[1])
      yAxisOptions[0] && setYAxis(yAxisOptions[0])
    }
  }, [xAxisOptions, yAxisOptions])

  // TODO
  // Check use-cases
  // For instance, when we change an axis value, the selected graph
  // or the values of an axis column!
  // useUpdatedValue([data], () => {
  //   for (let key in selectedValues) {
  //     delete selectedValues[key]
  //   }
  // })

  useUpdatedValue([primaryXAxis], () => {
    delete selectedValues.primaryXAxis
  })

  useUpdatedValue([secondaryXAxis], () => {
    delete selectedValues.secondaryXAxis
  })

  useUpdatedValue([yAxis], () => {
    delete selectedValues.yAxis
  })

  const loadData = () => {
    setPrimaryXAxis()
    setSecondaryXAxis()
    setYAxis()

    graphs !== null && setGraphs(null)

    fetch(DEFAULT_GRAPH_FILE_URL)
      .then((res) => (res.text()))
      .then((res) => {
        try {
          res = JSON.parse(res)
        } catch (ex) {
          throw new Error("Invalid content received, can't proceed with processing data.")
        }

        const graphs = processGraphResponse(res)
        setGraphs(graphs)
        setActiveGraph(graphs[0])
      })
      .catch((err) => {
        toast.error(err.message || DEFAULT_ERROR_MESSAGE)
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  return (
    <GraphContext.Provider
      value={{
        activeGraph,
        data,
        loadData,
        primaryXAxis,
        setPrimaryXAxis,
        secondaryXAxis,
        setSecondaryXAxis,
        yAxis,
        setYAxis,
        xAxisOptions,
        yAxisOptions,
        selectedValues,
        updateSelectedValue
      }}
    >
      <GraphSection />
    </GraphContext.Provider>
  )
}