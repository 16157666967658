import React from 'react'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Button } from '@mui/material'
import { auth } from '../../util/auth/firebase'
import GoogleLogo from '../custom-icon/GoogleLogo'
import { toast } from 'react-toastify'

const provider = new GoogleAuthProvider()

const GoogleLogin = () => {
  const initiateLogin = () => {
    // TODO
    // signIn through redirection when it's a mobile platform
    signInWithPopup(auth, provider)
      .catch(({ message }) => {
        toast.error(message)
      })
  }

  return (
    <Button
      onClick={initiateLogin}
      variant='outlined'
      startIcon={<GoogleLogo sx={{ width: 14, height: 14 }} />}
      sx={{ width: '100%' }}
    >
      Sign In with Google
    </Button>
  )
}

export default GoogleLogin