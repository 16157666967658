import React, { useContext, useEffect } from 'react'
import { Box, Chip, Divider, styled } from '@mui/material'
import AutocompleteWrapper from '../form-input/AutocompleteWrapper'
import GraphContext from './context'
import useUpdatedValue from '../../util/hooks/useUpdatedValue'
import { uniqBy } from 'lodash'
import ColumnValueInput from './helper/ColumnValueInput'
import getEvenChunks from '../../util/getEvenChunks'

// TODO
// Move this constants up to a different file!
const DEFAULT_PRIMARY_X_AXIS_VALUE_LEN = 10
const DEFAULT_SECONDARY_X_AXIS_CHUNK_LEN = 4
const DEFAULT_SECONDARY_X_AXIS_CHUNK_COUNT = 4

const ClearDiv = styled('div')({
  clear: 'both'
})

const defaultInputSx = { px: 2, pb: 2 }
const dividerSx = { mb: 2, bgcolor: 'warning.light', height: 2 }
const defaultRenderInputProps = {
  InputLabelProps: {
    sx: {
      fontWeight: 'bold',
      color: 'warning.main'
    }
  }
}
const defaultChipSx = {
  float: 'right',
  mt: -1,
  mr: 2,
  textTransform: 'capitalize',
  border: '1px solid',
  borderColor: 'dark.main'
}

export default function GraphPrimarySettings() {
  const {
    activeGraph,
    primaryXAxis, setPrimaryXAxis,
    secondaryXAxis, setSecondaryXAxis,
    yAxis, setYAxis,
    xAxisOptions, yAxisOptions,
    selectedValues, updateSelectedValue
  } = useContext(GraphContext)

  const { primaryXAxisInfo = {}, secondaryXAxisInfo = {} } = useUpdatedValue(
    [activeGraph, primaryXAxis, secondaryXAxis],
    () => {
      if (!activeGraph) { return }

      const res = { primaryXAxisInfo: {}, secondaryXAxisInfo: {} }

      const primaryXAxisKey = primaryXAxis?.value
      if (primaryXAxisKey) {
        const column = activeGraph.columns?.find(({ key }) => (key === primaryXAxisKey))
        if (!column) { return }

        const values = uniqBy(activeGraph.data, (item) => (item[primaryXAxisKey]))
          .map((item) => (item[primaryXAxisKey]))

        Object.assign(res.primaryXAxisInfo, { values, column })
      }

      const secondaryXAxisKey = secondaryXAxis?.value
      if (secondaryXAxisKey) {
        const column = activeGraph.columns?.find(({ key }) => (key === secondaryXAxisKey))
        if (!column) { return }

        const values = uniqBy(activeGraph.data, (item) => (item[secondaryXAxisKey]))
          .map((item) => (item[secondaryXAxisKey]))

        Object.assign(res.secondaryXAxisInfo, { values, column })
      }

      return res
    }) || {}

  const primaryXAxisValues = primaryXAxisInfo.values
  const secondaryXAxisValues = secondaryXAxisInfo.values

  const primaryXAxisType = primaryXAxisInfo.column?.type
  const secondaryXAxisType = secondaryXAxisInfo.column?.type

  // NOTE
  // Setting up initial values for the selected columns
  useEffect(() => {
    if (xAxisOptions && primaryXAxisValues) {
      updateSelectedValue('primaryXAxis', getEvenChunks({
        values: primaryXAxisValues,
        chunkLen: 1,
        chunkCount: DEFAULT_PRIMARY_X_AXIS_VALUE_LEN
      }))
    }
    // eslint-disable-next-line
  }, [primaryXAxisValues])

  useEffect(() => {
    if (yAxisOptions && secondaryXAxisValues) {
      updateSelectedValue('secondaryXAxis', getEvenChunks({
        values: secondaryXAxisValues,
        chunkLen: DEFAULT_SECONDARY_X_AXIS_CHUNK_LEN,
        chunkCount: DEFAULT_SECONDARY_X_AXIS_CHUNK_COUNT
      }))
    }
    // eslint-disable-next-line
  }, [secondaryXAxisValues])

  const getTypeChip = (value) => (
    value && (
      <>
        <Chip
          size='small'
          label={<>Type: <b>{value}</b></>}
          sx={defaultChipSx}
        />
        <ClearDiv sx={{ pb: 2 }} />
      </>
    )
  )

  return (
    <Box sx={{ width: '100%', pt: 2 }}>
      <AutocompleteWrapper
        id='primary-x-axis-key'
        value={primaryXAxis || null}
        onChange={(_, newValue) => {
          setPrimaryXAxis(newValue)
        }}
        options={xAxisOptions}
        label='Primary X Axis'
        sx={defaultInputSx}
        renderInputProps={defaultRenderInputProps}
      />
      {getTypeChip(primaryXAxisType)}
      {primaryXAxisValues &&
        <ColumnValueInput
          key={'primary-' + primaryXAxis.value}
          options={primaryXAxisValues}
          label='Primary X Axis Values'
          value={selectedValues.primaryXAxis}
          update={(newValue) => {
            updateSelectedValue('primaryXAxis', newValue)
          }}
          sx={defaultInputSx}
          column={primaryXAxisInfo.column}
        />
      }
      <Divider sx={dividerSx} />
      <AutocompleteWrapper
        id='secondary-x-axis-key'
        value={secondaryXAxis || null}
        onChange={(_, newValue) => {
          setSecondaryXAxis(newValue)
        }}
        options={xAxisOptions}
        label='Secondary X Axis'
        sx={defaultInputSx}
        renderInputProps={defaultRenderInputProps}
      />
      {getTypeChip(secondaryXAxisType)}
      {secondaryXAxisValues &&
        <ColumnValueInput
          key={'secondary-' + secondaryXAxis.value}
          options={secondaryXAxisValues}
          label='Secondary X Axis Values'
          value={selectedValues.secondaryXAxis}
          update={(newValue) => {
            updateSelectedValue('secondaryXAxis', newValue)
          }}
          sx={defaultInputSx}
          column={secondaryXAxisInfo.column}
        />
      }
      <Divider sx={dividerSx} />
      <AutocompleteWrapper
        id='y-axis-key'
        value={yAxis || null}
        onChange={(_, newValue) => {
          setYAxis(newValue)
        }}
        options={yAxisOptions}
        label='Y Axis'
        sx={defaultInputSx}
        renderInputProps={defaultRenderInputProps}
      />
      {/* // NOTE */}
      {/* yAxis only accepts `number` type as of now! */}
      {yAxis && getTypeChip('number')}
    </Box>
  )
}