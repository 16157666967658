import React, { useState } from 'react'
import AutocompleteWrapper from '../../form-input/AutocompleteWrapper'
import { Box, Checkbox, FormControlLabel, styled } from '@mui/material'
import ColumnDateValueInput from './DateInput'

const StyledDiv = styled('div')({
  textAlign: 'right'
})

export default function ColumnValueInput({
  options, label, value, update, sx,
  column
}) {
  const { type } = column
  const isDateType = type === 'date'
  const [hasTickbox, setHasTickbox] = useState(isDateType)

  return (
    <Box>
      {isDateType &&
        <StyledDiv sx={{ textAlign: 'right' }}>
          <FormControlLabel
            checked={hasTickbox}
            control={<Checkbox size='small' />}
            label='Use Tickbox'
            onChange={() => { setHasTickbox(!hasTickbox) }}
            sx={{ mt: -2 }}
          />
        </StyledDiv>
      }
      {hasTickbox ?
        <ColumnDateValueInput
          data={options}
          value={value}
          update={update}
          sx={sx}
        /> :
        <AutocompleteWrapper
          options={options}
          label={label}
          sx={sx}
          value={value || []}
          onChange={(_, newValue) => {
            update(newValue)
          }}
          multiple
        />
      }
    </Box>
  )
}