import React from 'react'
import { Divider, Grid, Paper, Typography } from '@mui/material'
import GraphPrimarySettings from './GraphPrimarySettings'
import GraphAdvancedConfiguration from './GraphAdvancedConfiguration'
import Graph from './Graph'

const settingsContainerRootSx = { display: 'flex' }

export default function GraphSection() {
  return (
    <Paper>
      <Typography
        variant='h6'
        align='left'
        pl={2} pr={2} pt={1} pb={1}
      >
        Graph Section
      </Typography>
      <Divider />
      <Grid container>
        <Grid container item md={4} xs={12}>
          <Grid
            item
            xs={12}
            height={600}
            overflow='auto'
            sx={settingsContainerRootSx}
          >
            <GraphPrimarySettings />
          </Grid>
          {null && <Divider sx={{ width: '100%' }} />}
          {null && <Grid
            item
            xs={12}
            minHeight={262}
            sx={settingsContainerRootSx}
            alignItems='center'
            justifyContent='center'>
            <GraphAdvancedConfiguration />
          </Grid>}
        </Grid>
        <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
        <Grid
          item
          md={8}
          xs={12}
          alignItems='center'
          justifyContent='center'
        >
          <Graph />
        </Grid>
      </Grid>
    </Paper>
  )
}