export default function getEvenChunks({
  values, chunkLen, chunkCount
}) {
  const { length } = values
  const defaultLength = chunkLen * chunkCount

  let res = []

  if (
    chunkCount > 1 &&
    length > defaultLength
  ) {
    res = values.slice(0, chunkLen)

    for (let index = 1; index < chunkCount - 1; index++) {
      let startIndex = Math.floor(
        (length / (chunkCount - 1)) * index -
        (chunkLen / 2)
      )

      res = res.concat(values.slice(
        startIndex,
        startIndex + chunkLen
      ))
    }

    res = res.concat(values.slice(length - chunkLen))
  } else {
    res = values.slice(0, defaultLength)
  }

  return res
}