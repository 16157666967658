import { Autocomplete, TextField } from '@mui/material'
import { merge } from 'lodash'
import React from 'react'

export default function AutocompleteWrapper({ label, renderInputProps, ...props }) {
  return (
    <Autocomplete
      {...props}
      renderInput={({ ...innerProps }) => {
        renderInputProps && merge(innerProps, renderInputProps)

        return props.renderInput({
          ...innerProps,
          label,
        })
      }}
    />
  )
}

AutocompleteWrapper.defaultProps = {
  size: 'small',
  renderInput: (params) => (<TextField {...params} />),
  isOptionEqualToValue: (option, value) => (
    value && option && ((option.value || option) === (value.value || value))
  ),
  getOptionLabel: (option) => (option.label || option)
}

AutocompleteWrapper.propTypes = Autocomplete.propTypes