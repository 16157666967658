import { Divider, Grid, Paper, Typography } from '@mui/material'
import Navbar from '../navbar/Navbar'
import GraphProvider from '../graph/provider'
import Advertisement from '../advertisement/Advertisement'

export default function Home() {
  return (
    <>
      <Navbar />
      <Grid container spacing={1} sx={{ mt: 1, pl: 2, pr: 2 }}>
        <Grid item md={10} xs={12}>
          <GraphProvider />
        </Grid>

        <Grid item md={2} xs={12}>
          <Paper>
            <Typography
              variant='h6'
              align='left'
              pl={2} pr={2} pt={1} pb={1}
            >
              Advertisement Section
            </Typography>
            <Divider />
            <Advertisement />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}