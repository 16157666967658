import React, { useState } from 'react'
import { signInAnonymously } from 'firebase/auth'
import { Button, CircularProgress } from '@mui/material'
import { auth } from '../../util/auth/firebase'
import { toast } from 'react-toastify'

export default function AnonymousLogin() {
  const [isLoading, setIsLoading] = useState(false)

  const initiateLogin = () => {
    setIsLoading(true)

    signInAnonymously(auth)
      .catch(({ message }) => {
        toast.error(message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Button
      onClick={initiateLogin}
      variant='outlined'
      color='info'
      sx={{ width: '100%' }}
      disabled={isLoading}
    >
      Continue as a Guest
      {isLoading && <CircularProgress
        size={24}
        sx={{ position: 'absolute' }}
      />}
    </Button>
  )
}