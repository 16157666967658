import React, { useEffect, useState } from 'react'
import { getUniqueID } from '../../util/common'

export default function Advertisement() {
  const id = useState(getUniqueID)[0]

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (ex) {
      if (ex.pbr === true) { return }
      throw ex
    }
  }, [])

  return (
    <ins
      className={id + ' adsbygoogle'}
      style={{ display: 'block' }}
      data-ad-client="ca-pub-2987909960726240"
      data-ad-slot="1248963528"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  )
}