import { useRef } from 'react'

const DEFAULT_VALUE = Symbol()

const usePrevious = function (value, props = {}) {
  const oldValueRef = useRef('defaultValue' in props ? props.defaultValue : DEFAULT_VALUE)
  const oldValue = oldValueRef.current
  const changed = Array.isArray(value) && Array.isArray(oldValue) &&
    value.length === oldValue.length ?
    value.some((item, index) => (item !== oldValue[index])) : oldValue !== value

  if (changed) { oldValueRef.current = value }

  return [oldValue, changed]
}

export default usePrevious