import { Chip, Divider, Grid } from '@mui/material'
import React from 'react'
import GoogleLogin from './GoogleLogin'
import AnonymousLogin from './AnonymousLogin'

export default function LoginProviders({ divider = true, ...props }) {
  return (
    <>
      {divider && <Divider sx={{ width: '100%', mt: 4, mb: 4 }}><Chip label='OR' /></Divider>}
      <Grid container spacing={2} {...props}>
        <Grid item xs={12}>
          <GoogleLogin />
        </Grid>
        <Grid item xs={12}>
          <AnonymousLogin />
        </Grid>
      </Grid>
    </>
  )
}