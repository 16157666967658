import React from 'react'
import { useContext } from 'react'
import GraphContext from './context'
import { Box, Skeleton, Typography } from '@mui/material'
import { BarChart, axisClasses } from '@mui/x-charts'
import { getKey } from '../../util/common'
import useUpdatedValue from '../../util/hooks/useUpdatedValue'

const DEFAULT_SKELETON_HEIGHT = 460
const DEFAULT_SKELETON_MIN_WIDTH = 400
const GRAPH_LETTER_WIDTH = 8
const GRAPH_SERIES_BAR_WIDTH = 14
const DEFAULT_GRAPH_TEXT_FONT_SIZE = 12
const Y_TICK_WIDTH = 50

const getMinContentWidth = (
  data,
  key,
  { fontSize = DEFAULT_GRAPH_TEXT_FONT_SIZE, padding = 0 } = {}
) => {
  data = data.map((item) => (
    getKey(item, key)?.toString() || null
  )).filter((text) => (text !== null))

  return data.length ?
    Math.max(
      ...data.map((text) => (text.length * GRAPH_LETTER_WIDTH))
    ) * fontSize / DEFAULT_GRAPH_TEXT_FONT_SIZE
    + padding : 0
}

function BarChartWrapper({ xAxis, series }) {
  const canRender = !(!xAxis || !xAxis[0]?.data.length || !series)

  // TODO
  // Get rid of magic values

  const { width, marginLeft } = useUpdatedValue([xAxis, series], () => {
    if (!canRender) { return {} }

    let marginLeft = getMinContentWidth(series, 'label', { fontSize: 16, padding: 8 })
    if (marginLeft) { marginLeft += Y_TICK_WIDTH }

    const width = Math.max(
      DEFAULT_SKELETON_MIN_WIDTH,
      series.length * (series[0].data.length || 0) * GRAPH_SERIES_BAR_WIDTH +
      marginLeft,
      getMinContentWidth([xAxis[0].label], null, { fontSize: 14 }) + marginLeft,
      getMinContentWidth(xAxis[0].data) * series[0].data.length + marginLeft
    )

    return { width, marginLeft }
  })

  if (!canRender) { return null }

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Box sx={{ width }}>
        <BarChart
          xAxis={xAxis}
          series={series}
          width={width}
          height={DEFAULT_SKELETON_HEIGHT}
          legend={{
            direction: 'column',
            position: {
              vertical: 'middle',
              horizontal: 'left'
            }
          }}
          margin={marginLeft ? {
            left: marginLeft
          } : null}
          sx={{
            "--ChartsLegend-itemWidth": marginLeft + "px",
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              transform: 'translateX(calc(-50% + ' + (
                250 + (marginLeft * .31)
              ) + 'px))',
              fontWeight: 500
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default function Graph() {
  const { data } = useContext(GraphContext)

  return (
    <Box
      sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'auto' }}
      p={2}
      justifyContent='center'
      alignItems='center'
    >
      {data ?
        <BarChartWrapper {...data} /> :
        data === null ?
          <Skeleton
            variant='rectangular'
            height={DEFAULT_SKELETON_HEIGHT}
            width={DEFAULT_SKELETON_MIN_WIDTH}
          /> :
          data === undefined ?
            <Typography variant='subtitle1' color='error'>
              Failed to load data.
            </Typography> :
            null
      }
    </Box>
  )
}