import { groupBy } from 'lodash'

export const isObject = (value) => {
  return typeof value === 'object' && !Array.isArray(value)
}

export function getUniqueID() {
  return 'u' + Math.random().toString(32).substring(2)
}

export function getKey(item, keyFn) {
  return typeof keyFn === 'function' ? keyFn(item) :
    keyFn ? item[keyFn] : item
}

export function getObj(data, keyFn) {
  let obj = {}
  data.forEach((item) => {
    obj[getKey(item, keyFn)] = item
  })
  return obj
}

export function isObjEmpty(obj) {
  for (let _ in obj) { return false }
  return true
}

export const groupByMultiple = function (_, groupKeys) {
  arguments[1] = (item) => {
    const json = {}
    groupKeys.forEach((key) => { json[key] = item[key] })
    return JSON.stringify(json)
  }

  return groupBy.apply(groupBy, arguments)
}

