import { useRef } from 'react'
import useForceUpdate from './useForceUpdate'

const useSubmit = (submitFunc) => {
  const forceUpdate = useForceUpdate()
  const isSubmittingRef = useRef(false)

  return [isSubmittingRef.current, (e, ...restArgs) => {
    e?.preventDefault?.()
    if (isSubmittingRef.current) { return }
    isSubmittingRef.current = true
    forceUpdate()

    submitFunc.apply(submitFunc, [() => {
      isSubmittingRef.current = false
      forceUpdate()
    }, e, ...restArgs])
  }]
}

export default useSubmit