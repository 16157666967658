// TODO
// Replace "AusData" values with a constant

import { Link, Typography } from '@mui/material'

export default function Copyright(props) {
  return (
    <Typography color='text.secondary' align='center' sx={{ mt: 4, pb: 4 }} {...props}>
      {'Copyright © '}
      <Link color='inherit' href='/'>
        AusData
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}