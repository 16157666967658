import './App.css'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import AuthContext from './util/auth/context'
import BasicSignUp from './component/basic-login/SignUp'
import Copyright from './component/copyright/Copyright'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Home from './component/home/Home'
import BasicSignIn from './component/basic-login/SignIn'

const theme = createTheme()

function App() {
  const { user } = useContext(AuthContext)

  const [router, setRouter] = useState()

  useEffect(() => {
    let route

    if (user) {
      route = (
        <Route>
          <Route
            path='*'
            element={<Home />}
          />
        </Route>
      )
    } else {
      route = (
        <Route>
          <Route
            path='/sign-up'
            element={<BasicSignUp />}
          />
          <Route
            path='/sign-in'
            element={<BasicSignIn />}
          />
          <Route
            path='*'
            element={<BasicSignIn />}
          />
        </Route>
      )
    }

    setRouter(createBrowserRouter(createRoutesFromElements(route)))
  }, [user])

  // TODO
  // Don't render null, we can at least show a loading screen! 
  if (!router) { return null }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
      <Copyright />
    </ThemeProvider>
  )
}

export default App
